import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Divider,
  Space,
  Typography,
  message,
  Spin,
} from "antd";
import { useForm ,useWatch} from "react-hook-form";

import {
  InputField,
  ReactSelectField,
} from "../../../../atoms/FormElement";
import { updatePDCStatus } from "../../ducks/actions";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { formatString } from "../../../../../utils/formatString";
import tcReducer from "../../../Properties/AddProperty/ducks/reducers/tcReducer";


var _ = require("lodash");
const defaultVal = {
  status: { value: 'pending', label: 'Pending' },
  paymentMode: "",
  paymentRef: "",
  bankName: "",
  bankNameRef: "",
  reason: { value: 'signature_mismatch ', label: 'Signature mismatch' },
  paymentStatus: { value: 'pending', label: 'Pending' },
  paymentModeType: { value: 'cheque', label: 'Cheque' }
};

const { Paragraph } = Typography;

const visibilityOptions = [
  { value: 'pending', label: 'Pending' },
  { value: 'paid', label: 'Paid' },
  { value: 'bounce', label: 'Bounce' },
  { value: 'cancelled', label: 'Cancelled' },
]

const paymentModeOptions = [
  { value: 'cheque', label: 'Cheque' },
  { value: 'cash', label: 'Cash' },
  { value: 'bank_transfer', label: 'Bank Transfer' },
  { value: 'credit_card', label: 'Credit Card' },
  { value: 'directDebit', label: 'Direct Debit' },
]

const bouncedReasons = [
  { value: '', label: 'Select' },
  { value: 'signature_mismatch', label: 'Signature mismatch' },
  { value: 'insufficient_funds', label: 'Insufficient funds' },
  { value: 'damaged_cheque', label: 'Damaged cheque' },
  { value: 'overwriting_on_cheque ', label: 'Overwriting on cheque ' },
  { value: 'incorrect_amount', label: 'Incorrect amount' },
]

const ModifyPaymentStatus = (props) => {


  const { data } = props;

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [chequeType, setCheque] = useState(null);
  const [paymentMode, setPaymentMode] = useState(null);
  const [paymentStatusOn, setPaymentStatusOn] = useState(null);
  const { control, handleSubmit, formState: { errors }, reset, setValue, getValues, setError ,clearErrors} = useForm({defaultValues: defaultVal});


  const status = getValues()?.status?.value
 

  const onBack = () => {
    reset();
    props.onCancel();
  };


  useEffect(() => {
    if(true) {
      setValue(
        "paymentStatus",
        { value: data?.params?.data?.chequeStatus, label: visibilityOptions.find(option => option.value?.toLowerCase() === data?.params?.data?.chequeStatus?.toLowerCase())?.label },
        { shouldValidate: true, }
      )
      setValue(
        "paymentModeType",
        { value:formatString(data?.params?.data?.modeOfPayment)?.toLowerCase() , label: paymentModeOptions.find(option => formatString(option.value)?.toLowerCase() === formatString(data?.params?.data?.modeOfPayment)?.toLowerCase())?.label },
        { shouldValidate: true, }
      )
      setCheque(data?.params?.data?.chequeStatus?.toLowerCase());
      setPaymentMode(data?.params?.data?.modeOfPayment?.toLowerCase()) 
      setValue("paymentRef", data?.params?.data?.documentNo, { shouldValidate: false });
      setValue("bankName", data?.params?.data?.bankName, { shouldValidate: false });
      setValue("bankNameRef", data?.params?.data?.bankName, { shouldValidate: false });
      setValue("reason", data.reason ? { value: data.reason, label: bouncedReasons.find(option => option.value === data.reason)?.label } : bouncedReasons[0]
      , { shouldValidate: false });
    } 
  }, [data])

  function removeEmptyValues(obj) {
    for (const key in obj) {
      if (obj[key] === '') {
        delete obj[key];
      }
    }
    return obj;
  }

  useEffect(() => {
    return () => {
      reset();
    }
  }, [])

  const onFinish = async (values) => {

     if((values?.paymentStatus?.value === 'bounce' || values?.paymentStatus?.value  === 'cancelled') && values?.paymentModeType?.value === 'cheque' && values?.reason?.value==""){
   
      setError('reason', { type: "manual", message: "Please select Reason" });
     
      return ;
    }else {
      setLoader(true); 
  let payload= {
    chequeStatus: values?.paymentStatus?.value,
    modeOfPayment:values?.paymentModeType?.value,
    documentNo:values?.paymentRef ,
    bankName: values?.bankName ? values?.bankName : values?.bankNameRef,
    reason: values?.reason?.value,
    penaltyFee: values?.bouncedChequeFee,
    penaltyPaymentMode: values?.bouncedChequePaymentMode?.value
  }


  try {
   const result =   await updatePDCStatus(
      {
        leaseId: data?.params?.data?.leaseId,
        pdcId: data?.params?.data?._id,
      },
      removeEmptyValues(payload)
    );

  if(result === 200){
    reset();
    setLoader(false);
    message.success("Status updated successfully");
    setSubmited(false);
    props.onUpdate();
  }

  } catch (error) {
    setLoader(false);
    setisError(true);
    const { response } = error;
    setErrorMessage(response?.data?.error);
  }
    }
  
  
  };

   // useWatch without a name will watch all fields in the form
   const allValues = useWatch({control});

  const checkPayments = ()=>{

    if(allValues?.paymentStatus?.value === 'paid' && allValues?.paymentModeType?.value === 'cheque'){
      return true;
    }else if(allValues?.paymentStatus?.value === 'bounce' && allValues?.paymentModeType?.value === 'cheque'){
      return true;
    }
    else if(allValues?.paymentStatus?.value === 'paid' && allValues?.paymentModeType?.value != 'cheque'){
      return false;
    }
  }
  return (
    <Form layout="vertical" onFinish={handleSubmit((e)=>onFinish(e))}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
        {
         true ? (
            <Row gutter={24}>

            { 
              <Col span={12}>
             <ReactSelectField
                fieldname="paymentModeType"
                 isRequired={true}
                 label="Payment Mode"
                 rules={{ required: "Payment Mode Required" }}
                 validate={errors.paymentModeType && "error"}
                 validMessage={errors.paymentModeType && errors.paymentModeType.message}
                 control={control}
                  iProps={{ placeholder: "Select payment mode", isDisabled: data?.params?.data?.chequeStatus === 'paid'}}
                   selectOption={paymentModeOptions}
                  onChange={(e) => {
                   if(e) setPaymentMode(e.value)
                   }}
               />
              </Col>}
              <Col span={12}>
              <ReactSelectField
                 isRequired={true}
                  fieldname="paymentStatus"
                  label="Payment Status"
                  selectOption={ paymentMode === 'cheque' ? 
                      chequeType === 'bounce' ? 
                      [{value: "paid", label: "Paid"}, {value: "cancelled", label: "cancelled"}] : 
                    visibilityOptions : 
                    [{value: "pending", label: "Pending"}, {value: "paid", label: "Paid"}, {value: "cancelled", label: "cancelled"}]
                  }
                  control={control}
                  iProps={{ placeholder: "Select payment status", isDisabled: data?.params?.data?.chequeStatus === 'paid'}}
                  rules={{ required: "Payment Staus Required" }}
                  validate={errors.paymentStatus && "error"}
                  validMessage={errors.paymentStatus && errors.paymentStatus.message}
                  onChange={(e) => {

                 
                    if(e){
                      paymentStatusOn === 'pending'  && clearErrors()
                      setPaymentStatusOn(e.value)
                    } 
                    }}
                />
              </Col>
             
              {
            
            
             ((allValues?.paymentStatus?.value !='cancelled')) &&

                
                 <Col span={12} style={allValues?.paymentStatus?.value =='pending' ?{display:'none'}:{display:'block'}}>
                   {
                    paymentStatusOn === 'pending' || allValues?.paymentStatus?.value == 'pending' 
                    ? 
                      <InputField
                        key={allValues?.paymentStatus?.value}
                        fieldname="paymentRefPending"
                        label="Payment Ref"
                        control={control}
                        iProps={{ placeholder: "Enter Payment Ref" }}
                        initValue=""
                      
                      />
                    :
                      <InputField
                        key={allValues?.paymentStatus?.value}
                        fieldname="paymentRef"
                        label="Payment Ref"
                        isRequired={true }
                        control={control}
                        iProps={{ placeholder: "Enter Payment Ref" }}
                        initValue=""
                        rules={{ required: "Payment Ref Required" }}
                        validate={errors.paymentRef && "error"}
                        validMessage={errors.paymentRef && errors.paymentRef.message}
                      />
                    }
                </Col>
              }


              {
              (( allValues?.paymentStatus?.value != 'cancelled' && !checkPayments())) &&
                <Col span={12} style={((allValues?.paymentStatus?.value == 'pending' || allValues?.paymentModeType?.value == 'cheque') && allValues?.paymentStatus?.value != 'cancelled' ) ? {display:'none'}:{display:'block'}}>
                  <InputField
                    fieldname="bankNameRef"
                    isRequired={false}
                    label={"Bank Name"}
                    control={control}
                    iProps={{ placeholder: "Enter Bank Name" }}
                    initValue=""
               
                  />
                </Col>
              }

{
              (allValues?.paymentStatus?.value != 'cancelled' && checkPayments()) &&
                <Col span={12} style={!checkPayments() ? {display:'none'}:{display:'block'}}>
                  <InputField
                    fieldname="bankName"
                    isRequired={true}
                    label={"Bank Name"}
                    control={control}
                    iProps={{ placeholder: "Enter Bank Name" }}
                    initValue=""
                    rules={{ required: "Bank Name Required" }}
                    validate={ errors.bankName && "error"}
                    validMessage={  errors.bankName && errors.bankName.message}
                  />
                </Col>
              }


              {
                (paymentMode === 'cheque' && (allValues?.paymentStatus?.value === 'bounce' || allValues?.paymentStatus?.value  === 'cancelled')) && 
                  <Col span={12}>
                    <ReactSelectField
                      fieldname="reason"
                      label="Reason"
                      validate={errors.reason && "error"}
                      control={control}
                      iProps={{ placeholder: "Select reason"}}
                      selectOption={bouncedReasons}
                      isRequired={true}
                      rules={{ required: "Please select Reason" }}
                      validMessage={
                        errors.reason && errors.reason.message
                      }
                     
                    />
                  </Col>
              }
              {
                (status !== 'paid') && <Col span={12}></Col> 
              }
              
              {
                (paymentMode === 'cheque' && allValues?.paymentStatus?.value === 'bounce') && 
                <Col span={12}>
                  <InputField
                    fieldname="bouncedChequeFee"
                    label="Bounced Cheque Fee"
                    control={control}
                    iProps={{ placeholder: "Bounced Cheque Fee", type: "number", }}
                    initValue=""
                  />
                </Col>
              }
              {
                (paymentMode === 'cheque' && (allValues?.paymentStatus?.value === 'bounce')) && 
                <Col span={12}>
                  <ReactSelectField
                    fieldname="bouncedChequePaymentMode"
                    label="Bounced Cheque Payment Mode"
                    control={control}
                    iProps={{ placeholder: "Enter Payment Mode" }}
                    selectOption={paymentModeOptions}
                  />
                </Col>
              }
            </Row>
          ) : (
            null
            
          )
        }
          <Row gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" onClick={onBack}>
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                {status === 'paid' ? 'Confirm payment' : 'Update'}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default ModifyPaymentStatus;