import React, { useState, useEffect } from "react";

// others
import dayjs from "dayjs";
import { Loading } from "./index";
import { useForm, useWatch } from "react-hook-form";
import Button from "../../../../molecules/Button";
import { useDispatch, useSelector } from "react-redux";
import { PopupConfirm } from "../../../../atoms/Popup";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row, Form, Radio, Space, message } from "antd";
import {
  createRenewal,
  updateBooking,
  getBookingDetail,
} from "../../ducks/actions";
import {
  InputField,
  InputRadio,
  ReactSelectField,
} from "../../../../atoms/FormElement";
import {
  options,
  apiDateFormat,
  commaFormatter,
  installmentsList,
  changeValueOptions,
} from "../utils";
import { isDisabled } from "@testing-library/user-event/dist/utils";

interface IProps {
  parentState: (obj: object) => void;
  onModalClose?: any;
  renewalFormConform?: Boolean;
}

let initialState = {
  visible: false,
  changeValue: "",
};


const index = (props: IProps) => {
  // hooks initialization
  const navigate: any = useNavigate();
  const dispatch: any = useDispatch();
  const { leaseId = "" } = useParams();

  // redux states
  const { bookingDetails } = useSelector((state: any) => state.newBooking);

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // states
  const [{ visible, changeValue }, setState] = useState(initialState);

  const updateState = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    setValue("numberOfInstallments", {
      label: bookingTerms?.numberOfInstallments,
      value: bookingTerms?.numberOfInstallments,
    });

    updateState({ changeValue: bookingTerms?.changeValue });
  }, []);

  const handleAmount = (e: any) => {
    let updatedRent: any = "";
    const value = parseInt(e.target.value);

    const amountType = getValues("amountType");
    const changeValue = getValues("changeValue");
    const annualBaseRent = getValues("annualBaseRent");

    if (changeValue === "increase" && amountType === "amount")
      updatedRent = annualBaseRent + value;
    else if (changeValue === "decrease" && amountType === "amount")
      updatedRent = annualBaseRent - value;
    else if (changeValue === "increase" && amountType === "percentage")
      updatedRent = annualBaseRent + (annualBaseRent * value) / 100;
    else if (changeValue === "decrease" && amountType === "percentage")
      updatedRent = annualBaseRent - (annualBaseRent * value) / 100;

    if (!value) updatedRent = value;

    setValue("renewalAmount", updatedRent);
  };

  const { bookingTerms } = bookingDetails;

  const onSubmit = async (data: any) => {


 

    let dataCopy = { ...data };

    dataCopy = {
      ...dataCopy,
      ...{ numberOfInstallments: dataCopy["numberOfInstallments"].value },
    };
    if (dataCopy?.renewalAmount)
      dataCopy = {
        ...dataCopy,
        ...{ renewalAmount: Math.round(dataCopy["renewalAmount"]) },
      };

    for (let key in dataCopy) {
      if (!["amountType", "changeValue"].includes(key)) {
        dataCopy[key] = parseInt(dataCopy[key]);
      }
    }

    try {

    
      if (!props?.renewalFormConform) {
        updateState({ visible: true });
        const response = await dispatch(
          !bookingDetails?.bookingTerms?.changeValue
            ? createRenewal({
              leaseId,
              body: {
                email: bookingDetails.tenantDetails.email,
                name: bookingDetails.tenantDetails.tenantNameEN,
                mobile: bookingDetails.tenantDetails.mobileNumber,
                unitCode: bookingDetails.propertyDetails.unitCode,
                arabicName: bookingDetails.tenantDetails.tenantNameAR,
                bookingTerms: {
                  ...bookingDetails.bookingTerms,
                  ...dataCopy,
                  ...{ billingCharges: [] },
                },
                leaseTerms: {
                  ...bookingDetails.leaseTerms,
                  ...{
                    leaseStartDate: apiDateFormat(
                      bookingDetails?.leaseTerms?.leaseStartDate
                    ),
                    leaseEndDate: apiDateFormat(
                      bookingDetails?.leaseTerms?.leaseEndDate
                    ),
                  },
                },
              },
            })
            :

            updateBooking({
              leaseId,
              body: {
                bookingTerms: { ...bookingDetails.bookingTerms, ...dataCopy },
              },
            }),

        );

        if (!bookingDetails?.bookingTerms?.changeValue)
          navigate(`/viewbookingform/${response?.data?.result?.leaseId}`, {
            state: { renewal: true },
          });

        await dispatch(getBookingDetail(response?.data?.result?.leaseId));

        message.success(response?.data.message);
        updateState({ visible: false });
        props.parentState({
          modalPayload: { iKey: "", title: "", extraText: "", open: false },
        });
      } else {
          
     
        //  for story 8455 Upcoming Renewals > Add buttons for Confirm Renewal and Confirm Move-out
        PopupConfirm({
          title: `This will set the tenant's renewal decision to Renewal Accepted. Are you sure you wish to proceed?`,
          onOk: async () => {
            updateState({ visible: true });
            let payload:any = { ...bookingDetails.bookingTerms, ...dataCopy };
            
            try{
              const response = await dispatch(
                updateBooking({
                  leaseId,
                  body: {
                      onlyBooking:true,
                      renewalToTenant:"renewal_accepted",
                      bookingTerms:payload,
                  },

                  
                }));

                  
            if (!bookingDetails?.bookingTerms?.changeValue)
              navigate(`/viewbookingform/${response?.data?.result?.leaseId}`, {
                state: { renewal: true },
              });

            await dispatch(getBookingDetail(response?.data?.result?.leaseId));

            message.success(response?.data.message);
            updateState({ visible: false });
            props.parentState({
              modalPayload: { iKey: "", title: "", extraText: "", open: false },
            });
            }catch(eror){
              updateState({ visible: false });
              props.parentState({ visible: false });
              message.error(
                eror.response?.data?.error ||
                eror.response?.data?.message
             );
            }
              
           
          },
          okText: "Yes",
        })



      }
    } catch (error) {
      updateState({ visible: false });
      props.parentState({ visible: false });
    
      message.error(
         error.response?.data?.error ||
         error.response?.data?.message
      );
    }



  };


  // Watch all form values
  const watchedValues = useWatch({
    control
  });

  //  chek if value are change in case of confirm lease renewal
  const [detectChange, setDetectChnage] = useState(false);
  useEffect(() => {
    if (Object.values(watchedValues)?.length > 0) {
      setDetectChnage(true)
    }
  }, [watchedValues]);


  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      className="bf-main"
      onFinish={handleSubmit(onSubmit)}
    >
      <Loading visible={visible} />

      <Col span={24}>
        <Col span={12}>
          <InputField
            disabled={true}
            control={control}
            fieldname="annualBaseRent"
            label="Current Annual Rent"
            initValue={bookingTerms?.annualBaseRent}
            iProps={{
              formatter: (value: string): any => commaFormatter(value),
            }}
          />
        </Col>

        <Col span={24}>
          <InputRadio
            
            disabled={bookingDetails?.isAgp?.toLowerCase() === 'yes'}
            isRequired={true}
            control={control}
            fieldname="changeValue"
            initValue={bookingTerms?.changeValue}
            label="Increase or decrease your rent?"
            validate={errors.changeValue && "error"}
            validMessage={errors.changeValue && errors.changeValue.message}
            rules={{
              required: "This field is required",
            }}
            onChange={(e: any) => { 
              updateState({ changeValue: e.target.value });
              setValue("amountChange", "");
              if (e.target.value === "no_change")
                setValue("renewalAmount", bookingTerms?.annualBaseRent);
              else setValue("renewalAmount", "");
            }}
            
            options={
              <Row gutter={[16, 16]}>
                {changeValueOptions.map((option, i) => (
                  <Col span={8} key={`${option.value}-${i}`}>
                    <Radio  value={option.value}>{option.label}</Radio>
                  </Col>
                ))}
              </Row>
            }
          />
        </Col>

        {changeValue && changeValue !== "no_change" && (
          <>
            <Col span={24}>
              <InputRadio
                disabled={bookingDetails?.isAgp?.toLowerCase() === 'yes'}
                isRequired={true}
                control={control}
                fieldname="amountType"
                label="Type of Adjustment"
                initValue={bookingTerms?.amountType}
                validate={errors.amountType && "error"}
                validMessage={errors.amountType && errors.amountType.message}
                rules={{
                  required: "This field is required",
                }}
                onChange={() => {
                  setValue("amountChange", "");
                  setValue("renewalAmount", "");
                }}
                options={
                  <Row gutter={[16, 16]}>
                    {options.map((option, i) => (
                      <Col span={8} key={`${option.value}-${i}`}>
                        <Radio value={option.value}>{option.label}</Radio>
                      </Col>
                    ))}
                  </Row>
                }
              />
            </Col>

            <Col span={16}>
              <InputField
                disabled={bookingDetails?.isAgp?.toLowerCase() === 'yes'}
                isRequired={true}
                control={control}
                onChange={handleAmount}
                fieldname="amountChange"
                label="Amount of Adjustment"
                initValue={bookingTerms?.amountChange}
                validate={errors.amountChange && "error"}
                // disabled={getValues("amountType") ? false : true}
                validMessage={errors.amountChange && errors.message}
                rules={{
                  required: "This field is required",
                }}
              />
            </Col>
          </>
        )}

        {changeValue && (
          <Col span={16}>
            <InputField
              disabled={true}
              isRequired={true}
              control={control}
              fieldname="renewalAmount"
              initValue={bookingTerms?.renewalAmount}
              validate={errors.renewalAmount && "error"}
              validMessage={errors.renewalAmount && errors.message}
              label={
                changeValue === "no_change"
                  ? "Rent Amount"
                  : "Updated Rent Amount"
              }
              rules={{
                required: "This field is required",
              }}
              iProps={{
                formatter: (value: string): any => commaFormatter(value),
              }}
            />
          </Col>
        )}

        <Col span={16}>
          <ReactSelectField
           
            control={control}
         
            isRequired={true}
            label="No. of Instalments"
            fieldname="numberOfInstallments"
            selectOption={installmentsList}
            iProps={ { 
              placeholder: "Select one",
              isDisabled:bookingDetails?.isAgp?.toLowerCase() === 'yes' ? true : false
             }
           
            }
            validate={errors.numberOfInstallments && "error"}
            rules={{ required: "Please select no. of installments" }}
            validMessage={
              errors.numberOfInstallments && errors.numberOfInstallments.message
            }
          />
        </Col>

        <Row gutter={24} className="footer">
          <Space size="middle">
            <Button
              label="Cancel"
              className="bf-discard-button"
              onClick={() =>
                props.onModalClose()
              }
            />

            {
              props?.renewalFormConform ?
                <Button
                  disabled={!detectChange && bookingDetails?.isAgp?.toLowerCase() != 'yes'}
                  type="submit"
                  className="cc-fotter_next-button"
                  label={"Confirm"}
                />
                :
                <Button
                  type="submit"
                  className="cc-fotter_next-button"
                  label={bookingTerms?.changeValue ? "Update" : "Create"}
                />
            }
          </Space>
        </Row>
      </Col>
    </Form>
  );
};

export default index;
