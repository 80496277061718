import * as action_types from "./constants";
import axios from "../../../../utils/axiosInceptor";
import {message} from 'antd';
import {
  baseContractService,
  contractService,
  leaseService,
} from "../../../../configs/constants";

export const getContactList = (limit = 10, page = 1, value = "", field, filter = {},setExportData=null,getContactList=null) => {
  let params = new URLSearchParams({
    ...filter,
  });
  return async (dispatch) => {
    const url = `${contractService}/leasing/getAllDueRenewalLease?limit=${limit}&page=${page}&searchby=${`${field}&text=${value}&`+params.toString()}`;
    const response = await axios.get(url);
    getContactList && getContactList(false)
    if(filter['exportData']){
      message.success(response?.data?.result);
      setExportData(false);
    }else{
      dispatch({
        type: action_types.GET_CONTRACTS,
        data: response?.data?.result,
      });
    }
   
  };
};

export const getBUList = async () => {
  const orgId = JSON.parse(window.localStorage.getItem("login-response"))?.orgId;

    const url = `${contractService}/businessUnitmaster/list?orgId=${orgId}`;
    const response = await axios.get(url);
    if(response?.data?.result) return response?.data?.result

};

export const setFilters = data => {
  
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_RENEWAL_FILTERS,
      data: data,
    });
  }
}

export const clearRenewalFilters = () => {
  
  return async (dispatch) => {
    dispatch({
      type: action_types.SET_RENEWAL_FILTERS,
      data: {},
    });
  }
}

export const getContactDetail = (id) => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-lease-renewal?id=${id}`;
    const response = await axios.get(url);
    dispatch({
      type: action_types.GET_CONTRACT,
      data: response?.data?.result,
    });
  };
};

export const getPDC = (data, limit = 10, page = 1,setIsLoading,setExportData=null) => {
  
 if(data?.chequeStatus === 'all') 
    data.chequeStatus = 'pending';

  let params = new URLSearchParams({
    ...data,
  });

  return async (dispatch,getState) => { 
    const currentState = getState();
    try{
      
    const url = `${leaseService}/booking/pdclist?limit=${limit}&page=${page}&`+params.toString();  
    const response = await axios.get(url);
    setIsLoading(false)
    if(data?.exportData){
      message.success(response?.data?.result);
      setExportData && setExportData(false);
      dispatch({
        type: action_types.GET_PDC,
        data: currentState?.contracts?.pdcList,
      });
    }else{
      dispatch({
        type: action_types.GET_PDC,
        data: response?.data?.result,
      });
    }
   
    }catch(error){
      setIsLoading(false)
      console.log(error)
    }
  };
};

export const getOnePDC = async  (pdc) => {

  const url = `${leaseService}/booking/pdc/${pdc}`
  return await axios.get(url);

};

export const updatePDCStatus = async (params, data) => {
  try {
    const url = `${leaseService}/booking/paymentSchedule/${params.leaseId}/${params.pdcId}`;
    const { status } = await axios.patch(url, data);
    return status;

  } catch (error) { }
};
