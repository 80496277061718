import React, { useState, useEffect, Fragment } from "react";
// import { Popup } from "../../atoms/Popup";
import dayjs from "dayjs";
import { Card, Row, Col, Typography, Button, Image, Upload, Space, Radio, Switch, Tag, message, Tooltip } from "antd";
import { DownloadOutlined, EyeOutlined, FileTextOutlined, UploadOutlined } from "@ant-design/icons";
import { InputField } from "../../../../../atoms/FormElement";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { getBookingDetail, updateBookingStatus } from "../../../ducks/actions";
import { updateDocumentExpiry } from "../../../ducks/services";
import { useDispatch } from "react-redux";
import { DeleteIcon } from "../../../../../../assets/svg";
import { Popup, PopupConfirm } from "../../../../../atoms/Popup";
import { getPresignedImage } from "../../../../../../utils/media";
import PdfView from "../../../../../molecules/PDFViewer/pdvViewNew";
import RejectPopup from "../../modalContent/rejectPopup";
import DocExpiry from "../../modalContent/DocExpiry";

const reasonsData = [
  { value: "incorrect_information", label: "Incorrect Information" },
  { value: "incomplete_documents", label: "Incomplete Documents" },
  { value: "incomplete_amounts", label: "Incomplete Amounts" },
];

const RequiredDocs = (props) => {
  const [visible, Setvisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [expiryVisible, setExpiryVisible] = useState(false);
  const[uploadFile,setUploadFile] = useState(null);
  const { control, index, document, disable, docLabel, setState, uploadRequiredDocument,requiredDocuments } = props;
  const { Title } = Typography;
  const [viewOrDownloadUrl, setViewOrDownloadUrl] = useState(null);

  const [openFileView, setOpenFileView] = useState(false);

  const [file, setFile] = useState([]);


  const onClosePopUp = () => {
    Setvisible(false);
  };

  const dispatch: any = useDispatch();

  // redux states
  const { bookingDetails } = useSelector(
    (state: any) => state.newBooking
  );

  const { leaseId = bookingDetails.leaseId } = useParams();


  const onOpenPopUpHandler = async () => {
  
    getUrl()
    if (document && document?.documentFileType === "pdf") {
      setOpenFileView(!openFileView);
    } else if (
      Object.keys(file).length &&
      !Array.isArray(file) &&
      // @ts-ignore
      file?.name.includes("pdf")
    ) {
      let urlf = window.URL.createObjectURL(file["originFileObj"]);
      setViewOrDownloadUrl(urlf);
      setOpenFileView(!openFileView);
    } else {
      Setvisible(true);
    }
  };

  const getUrl = async () => {
    const url = await getPresignedImage([document?.documentId]);
    setViewOrDownloadUrl(url[0]);
  };

  const downloadUrl = async () => {
    const url = await getPresignedImage([document?.documentId], false);
    if (url)
      window.location.href = url[0];
  }



  const ApproveDocument = async (type,labelKey=null) => {
   
    // if type  === 1 it's mean we are going to delete that doc 
      let payload:object = {};
       type === 1 ? payload = requiredDocuments?.filter((item)=>item?.type !=labelKey)
       :
       payload =  {
        status: "tenant_documents_approved",
        documentType: document?.type ?? "",
        comments: document?.type
      }
      
      PopupConfirm({
        title: `Are you sure you want to  ${type === 1 ? 'delete' : 'approve'} ${document?.title || getDocLabel(document?.type)}?`,
        okText: "Yes",
        onOk: async () => {
          // setState({ loading: true });
          let body = type == 1 ? {'requiredDocuments':payload} : payload;
          try {

            const res = await dispatch(updateBookingStatus({ leaseId, body }));
            message.success(`Successfully ${type === 1 ? 'delete' : 'approved'} document`);
            setState({ loading: false });
            await dispatch(getBookingDetail(leaseId));  //added to fix transformed resp issue: ref bug: app-rej doc buttons disappear
          } catch (error) {
            setState({ loading: false });
            type === 1 ? message.error("Failed to delete") : message.error("Failed to approve") 
          }
        },
      });
    

    
  };

  const RejectDocument = async (data) => {
    setState({ loading: true });
    let body = {
      status: "tenant_documents_partial_reject",
      documentType: document?.type ?? "",
      "reason": data?.reason,
      "comments": data?.comments
    }
    if (document?.type === "Other")
      body['otherDocumentLabel'] = document?.documentLabel;
    try {
      const res = await dispatch(updateBookingStatus({ leaseId, body }));
      message.success("Rejected document");
      setState({ loading: false });
      setRejectVisible(false);
      await dispatch(getBookingDetail(leaseId)); //added to fix transformed resp issue ref bug: app-rej doc buttons disappear
    } catch (error) {
      setState({ loading: false });
      message.error("Failed to reject")
    }
  };

  const popupContent = {
    closable: true,
    visibility: visible,
    content: (
      <Image
        preview={false}
        width="100%"
        style={{ padding: "20px" }}
        src={viewOrDownloadUrl}
      />
    ),
    width: 650,
    onCancel: onClosePopUp,
  };

  const rejectPopup = {
    title: <Title level={3} className="mb-0">
      Reject confirmation
    </Title>,
    closable: true,
    visibility: rejectVisible,
    content: (
      <RejectPopup
        handleReject={RejectDocument}
        onClose={() => setRejectVisible(false)}
        reasonsData={reasonsData} 
      />
    ),
    width: 650,
    onCancel: () => setRejectVisible(false),
  };

  const getDocLabel = (key) => {
    if (docLabel?.length > 0) {
      let label = docLabel?.filter(item => item?.value == key)?.[0]?.label;
      if (label == "Other") return document?.documentLabel;
      else return label;
    }
    else return key;

  }

  const fileProps = {
    className: "file-upload",
    name: "file",
    accept: '.jpg,.png,.jpeg,.pdf',
    multiple: false,
    showUploadList: false,
    onChange(info) {

      const { status } = info.file;
   
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} Please upload only image file`);
      }
    },
  
    beforeUpload: async (file) => {

     
      if(!document?.isExpiry){
        let uploadConfirmation = await uploadRequiredDocument(file, document?.type);
        if(uploadConfirmation?.documentId){
          setExpiryVisible(previous => !previous);
        }   
      }else{
        setUploadFile(file)
      }
        return false;
    },
  };

  // FIX FOR BUG: 7970
  const isUpload = () => {
    let upload = true;
    if (bookingDetails?.leaseType == "renewal")
      if (bookingDetails?.renewalStatus == "renewal_accepted") upload = true; //added to hold the status from changing to documents_submitted
      else upload = false;
    else upload = true;
    return upload && (document?.isExpired == false || (document?.status !== "approved" && document?.status !== "expired"))
  }

  const getDocumentExpiry = async (data)=>{
    const {docExpiry,documentId} = data;
    const date = dayjs(new Date(docExpiry.$y, docExpiry.$M, docExpiry.$D, docExpiry.$H, docExpiry.$m, docExpiry.$s, docExpiry.$ms));

     // Format the date as "YYYY-MM-DD"
      const formattedDate = date.format("YYYY-MM-DD");
 
        let payload= {
        "type":document?.type,
        "expiryDate":formattedDate,
        "docId":documentId,

}
 let result = await updateDocumentExpiry(leaseId ,payload)
    
   if(result?.data.code == 200){
    setExpiryVisible(false);
    let uploadConfirmation = await uploadRequiredDocument(uploadFile, document?.type);
    if(uploadConfirmation?.documentId){
      setExpiryVisible(false);
     
    }  
   
   
    setUploadFile(null)
    return result?.data.code; 
   }
  } 

  const popupForGetDocExpiry = {
    title:<Title level={3} className="mb-0">
    Upload {document?.title} 
  </Title>,
    closable: true,
    visibility: expiryVisible,
    content: (
      <DocExpiry
      fileProps={fileProps}
      getDocumentExpiry={(data)=>getDocumentExpiry(data)} 
      document={document}
      uploadFile={uploadFile}
      
      />
    ),
    width: 650,
    onCancel:()=>setExpiryVisible(false),
  };


  return (
    <Fragment>
      <PdfView
        pdfData={viewOrDownloadUrl}
        openFileView={openFileView}
        setOpenFileView={setOpenFileView}
      />
      <Card bordered={false} className="document-card">
        <Row gutter={24} justify="space-between" align="middle">
          <Col span={12}>
            <Row gutter={24}>
              <Col span={12} style={{ display: "flex" }}>
                <FileTextOutlined className="require-doc" />
                <Title level={5}>{document?.title || getDocLabel(document?.type)}</Title>
              </Col>
              <Col span={6}>
                <InputField
                  classes="fm-input-hidden"
                  fieldname={`requiredDocuments[${index}].type`}
                  control={control}
                  initValue={document?.type}
                  iProps={{ readOnly: true, type: "hidden" }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={2}></Col>
              <Col span={10} style={{ display: "flex", justifyContent: "end", paddingRight: "20px" }}>

                <Space size={20}>
                  {isUpload() && (
                    <Tooltip title="Upload" trigger="hover" showArrow={false}>

                        <Button type="link" className="ef-action-btn" onClick={()=>setExpiryVisible(true)} >
                          <UploadOutlined />
                        </Button>
                  
                    </Tooltip> 
                  )}
                  {(document?.documentId) && (<>
                  
                    <Tooltip title="Download" trigger="hover" showArrow={false}>
                      <Button type="link" className="ef-action-btn" onClick={() => downloadUrl()}>
                        <DownloadOutlined />
                      </Button>
                    </Tooltip>
                    <Tooltip title="View" trigger="hover" showArrow={false}>
                      <Button type="link" className="ef-action-btn" onClick={onOpenPopUpHandler}>
                        <EyeOutlined />
                      </Button>
                    </Tooltip>
                   
                  </>)}

                  { (!document?.documentId && document?.status === "pending" && requiredDocuments?.length > 1) &&
                     <Tooltip title="Delete" trigger="hover" showArrow={false}>
                      <Button type="link" className="ef-action-btn" onClick={()=>ApproveDocument(1,document?.type )}>
                        <DeleteIcon />
                      </Button>
                    </Tooltip>} 
                </Space>
              </Col>
              <Col span={12}>
                {(document?.status == "rejected" || document?.status == "approved" || !document?.documentId) && document?.isExpired != true && (
                  <>
                    {document?.status == "approved" && (
                      <Tag className="ef-tag tag-success">
                        Approved
                      </Tag>
                    )}
                    {document?.status == "rejected" && (
                      <Tag className="ef-tag tag-danger">
                        Rejected
                      </Tag>
                    )}
                    {document?.status?.toLowerCase() == "pending" && !document?.documentId && (
                      <Tag className="ef-tag tag-pending">
                        Pending
                      </Tag>
                    )}
                  </>
                )}
                {((document?.isExpired == true) &&
                  <Tag className="ef-tag tag-pending">
                    Document Expired
                  </Tag>
                )}

                {document?.status != "rejected" && document?.status != "approved" && document?.status == "pending" && document?.documentId && ( //
                  <Space size={18}>
                    <Button
                      type="primary"
                      className="ef-approve-btn"
                      disabled={disable}
                      onClick={() =>
                        ApproveDocument(2,null)}
                    >Approve</Button>
                    <Button
                      className="ef-reject-btn"
                      disabled={disable}
                      onClick={() => setRejectVisible(true)}
                    >Reject</Button>
                  </Space>
                )}

                   
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Popup {...popupForGetDocExpiry} />
      <Popup {...popupContent} />
      <Popup {...rejectPopup} destroyOnClose={true} />
    </Fragment >
  );
};

export default RequiredDocs;
